<template>
  <div class="ai">
    <header-bar :transparent="true" :showBack="true"></header-bar>

    <div class="container">
      <div class="bar">
        <div class="bar-item" @click="tolink('Subscribe')">{{ $t('AiData.dingyue') }}</div>
        <div class="bar-item" @click="tolink('aiLogin')">{{ $t('AiData.jilu') }}</div>
      </div>

      <div class="card q-mt-sm">
        <div class="row">
          <div class="col-md-6 col-xs-12 border-right">
            <div class="card-title">/usr/news — -bash</div>
            <!-- <vue3-seamless-scroll :list="linkList" direction="up" :step="0.5" class="scroll">
              <div class="link-item" v-for="(item, index) in linkList" :key="index">
                <span>[INFO] {{ item }}</span>
              </div>
            </vue3-seamless-scroll> -->
            <div class="scroll" ref="linkRef">
              <div class="link-item" v-for="(item, index) in linkList" :key="index">
                <span>[INFO] {{ item }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xs-12">
            <div class="card-title">/usr/hash — -bash</div>
            <!-- <vue3-seamless-scroll :list="hashList" direction="up" :step="0.5" class="scroll">
              <div class="hash-item" v-for="(item, index) in hashList" :key="index">{{ item }}</div>
            </vue3-seamless-scroll> -->
            <div class="scroll" ref="hashRef">
              <div class="hash-item" v-for="(item, index) in hashList" :key="index">
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 col-xs-12 border-right">
            <div class="card-title">{{ $t('AiData.jiaoyidui') }}</div>
            <div class="tabs">
              <div class="row text-center tabs-name border-bottom">
                <div class="col">{{ $t('AiData.paixu') }}</div>
                <div class="col">{{ $t('AiData.bizhong') }}</div>
                <div class="col">{{ $t('AiData.price') }}</div>
                <div class="col">{{ $t('AiData.price24') }}</div>
              </div>
              <div class="row text-center tabs-val" v-for="(item,index) in ethList" :key="item.id">
                <div class="col">{{ index + 1 }}</div>
                <div class="col">{{ item.symbol }}</div>
                <div class="col price">{{ item.priceOut.toFixed(6) }}</div>
                <div class="col price">{{ item.priceOut24.toFixed(6) }}</div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <div class="card-title">{{ $t('AiData.chengjiaotu') }}</div>
            <div class="q-pa-sm">
              <div id="echarts" class="echarts"></div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row items-center">
            <img src="@/assets/img/base/xinhao.png" width="14" />
            <div class="q-ml-sm">{{ $t('AiData.wending') }}</div>
          </div>
          <div class="row">
            <img src="@/assets/img/base/xiaoxi.png" width="16" height="16" />
            <div class="q-ml-sm">{{ $t('AiData.zhichi') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import HeaderBar from '@/components/HeaderBar'
import link from '@/assets/json/link.json'
import * as echarts from 'echarts';

export default {
  components: {
    HeaderBar
  },
  setup() {
    return {
      linkList: ref([]),
      linkTime: ref(null),
      hashList: ref([]),
      hashTime: ref(null),

      ethList: ref([]),
      myChart: null,
    }
  },
  created() {
  },
  mounted() {
    this.getEthContract()
    this.addLinkList()
    this.addHashList()
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
    clearTimeout(this.linkTime)
    clearTimeout(this.hashTime)
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    // 屏幕变化触发
    handleResize() {
      this.myChart.resize()
    },
    replaceMiddleFourCharacters(hash) {
      const start = hash.substring(0, 28);
      const middleStars = "****";
      const end = hash.substring(hash.length - 28);
      return start + middleStars + end;
    },
    // 随机生成60位哈希值
    generateRandomHashes() {
      let hash = '';
      const characters = '0123456789abcdef';
      for (let j = 0; j < 60; j++) {
        hash += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return this.replaceMiddleFourCharacters(hash)
    },
    // 生成随机数
    generateRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    // 判断数组超过一百删除第一个数据，防止数据过多
    removeFirstIfMoreThan100(arr) {
      if (arr.length > 100) {
        arr.shift();
      }
    },
    // 逐步添加链接数据
    addLinkList() {
      clearTimeout(this.linkTime)
      this.linkTime = setTimeout(() => {
        let list = link.list
        if(this.linkList.length < 3) {
          this.linkList.push("Loading...")
        } else {
          let index = this.generateRandomNumber(0, list.length - 1)
          this.linkList.push(link.list[index])
        }
        this.removeFirstIfMoreThan100(this.linkList)
        let container = this.$refs.linkRef;
        this.$nextTick(() => {
          container.scrollTop = container.scrollHeight;
        })
        this.addLinkList()
      }, this.generateRandomNumber(200, 400))
    },
    // 逐步添加哈希值数据
    addHashList() {
      clearTimeout(this.hashTime)
      this.hashTime = setTimeout(() => {
        this.hashList.push(this.generateRandomHashes())
        this.removeFirstIfMoreThan100(this.hashList)
        let container = this.$refs.hashRef;
        this.$nextTick(() => {
          container.scrollTop = container.scrollHeight;
        })
        this.addHashList()
      }, this.generateRandomNumber(200, 400))
    },
    initEcharts() {
      let _this = this
      this.myChart = echarts.init(document.getElementById('echarts'))
      let option = {
        tooltip: {
          formatter: function (info) {
            return `<div>${info.name}</div> <div>成交量：$${info.value}M</div>`
          }
        },
        series: [
          {
            name: _this.$t('AiData.chengjiaoliang'),
            type: 'treemap',
            roam: false,
            nodeClick: false,
            width: "100%",
            height: "100%",
            data: _this.ethList
          }
        ]
      };
      this.myChart.setOption(option)
    },
    getEthContract() {
      let _this = this
      _this.$request.post(
        "api/EthContract/List",
        {},
        (res) => {
          if (res.data.code == 0) {
						_this.$q.dialog({
							message: res.data.msg,
						});
					}
          let data = res.data.data
          let dogeIndex = data.findIndex(item => item.symbol === 'DOGE')
          let doge = data.splice(dogeIndex, 1)
          data.splice(3, 0, ...doge)
          data.forEach(item => {
            item.name = item.symbol
            item.value = this.generateRandomNumber(5, 100)
          })
          _this.ethList = data
          _this.initEcharts()
          // console.log(data)
        }
      )
    }
  }
}
</script>

<style scoped>
  .ai {
    position: relative;
    min-height: 100vh;
    background-color: #fff;
  }

  .container {
    margin-top: -30px;
    padding: 10px;
  }

  .card {
    color: #fff;
    border-radius: 10px;
    background-color: #000;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  .card-title {
    padding: 10px 0;
    font-weight: bold;
    text-align: center;
    color: #F2F2F2;
    background-color: #16181A;
  }

  .scroll {
    padding: 10px 15px;
    height: 210px;
    overflow: auto;
    word-wrap: break-word;
    font-size: 12px;
    box-sizing: border-box;
    scrollbar-width: 0;
    -ms-overflow-style: none;
  }

  .scroll::-webkit-scrollbar {
    display: none;
  }

  .link-item:nth-child(odd) {
    color: #20CD32;
  }

  .link-item:nth-child(even) {
    color: #DF3D16;
  }

  .link-item:nth-child(3n) {
    color: #12AAC4;
  }

  .link-item:nth-child(5n) {
    color: #FFD700;
  }

  .border-right {
    border-right: 1px solid rgba(66, 64, 64, 0.4);
  }

  .border-bottom {
    border-bottom: 1px solid rgba(66, 64, 64, 0.4);
  }

  .tabs {
    height: 300px;
    font-size: 13px;
    overflow: auto;
  }

  .tabs-name {
    padding: 6px 0;
    background-color: #111417;
  }

  .tabs-val {
    padding: 6px 0;
  }

  .tabs-val:nth-child(odd) {
    background-color: #111417;
  }

  .tabs-val:nth-child(even) {
    background-color: #1A212D;
  }

  .price {
    color: #FB7171;
  }

  .echarts {
    width: 100%;
    height: 300px;
  }

  .card-footer {
    padding: 4px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    border-top: 1px solid rgba(66, 64, 64, 0.4);
  }

  .bar {
    padding: 10px;
    display: flex;
    border-radius: 10px;
    background-color: #16181A;
  }

  .bar-item {
    flex: 1;
    text-align: center;
    color: #fff;
    font-size: 16px;
    color: #2C8B9F;
    text-decoration: underline;
  }

  .bar-item:first-child {
    border-right: 1px solid rgba(92, 88, 88, 0.4);
  }

  @media (max-width: 768px) {
    /* .scroll {
      padding: 0 15px;
      height: 50px;
    } */
  }
</style>